/* eslint-disable jsx-a11y/alt-text */
import { React, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBook, faQrcode, faTape } from '@fortawesome/free-solid-svg-icons';
import { FilterAlt, FilterAltOff } from '@mui/icons-material';
import CustomDatePicker from '../../../shared/components/CustomDatePicker';

export default function Filters({ 
    tabId,
    filterStatus,
    filterValues,
    filtersVisible,
    selectedPedidos,
    massEditMode,
    isAdmin,
    onFilterChange,
    onToggleMassEditMode,
    onExecuteMassiveAction,
    onToggleFilters,
    onChangeStatus
}) {
    const estados = useSelector(state => state.estados);
    const transportistas = useSelector(state => state.transportistas);

    const [id, setId] = useState("");
    const [presupuesto, setPresupuesto] = useState("");
    const [fecha, setFecha] = useState(null);
    const [transportistaId, setTransportistaId] = useState(-1);

    useEffect(() => {
        if(filterValues) {
            setId(filterValues.id);
            setPresupuesto(filterValues.numero_presupuesto);
            setFecha(filterValues.fecha)
            setTransportistaId(filterValues.transportista_id)
        }
    }, [
        filterStatus, 
        filterValues, 
        filtersVisible, 
        massEditMode,
        selectedPedidos
    ]);

    const handleFilter = () => {
        onFilterChange({
            id,
            numero_presupuesto: presupuesto,
            fecha,
            transportista_id: transportistaId
        })
    }

    const renderFilterControls = () => (
        <div className="d-flex flex-wrap flex-md-nowrap align-items-center mt-3 mt-md-0">
            <div className='form-group mb-3 mb-md-0 me-0 me-md-3'>
                <input 
                    type='text'
                    name='id'
                    className='form-control me-0 me-md-2'
                    placeholder='Filtrar por ID'
                    value={id}
                    onChange={(e) => setId(e.target.value)}
                />
            </div>

            <div className='form-group mb-3 mb-md-0 me-0 me-md-3'>
                <input 
                    type='text'
                    name='numero_presupuesto'
                    className='form-control me-0 me-md-2'
                    placeholder='Filtrar por Nº Presupuesto'
                    value={presupuesto}
                    onChange={(e) => setPresupuesto(e.target.value)}
                />
            </div>

            <div className='form-group mb-3 mb-md-0 me-0 me-md-3'>
                <select 
                    name='transportista_id' id='transportista_id' 
                    value={transportistaId}
                    onChange={(e) => setTransportistaId(parseInt(e.target.value))}
                >
                    <option value={-1}>TODOS</option>
                    { transportistas.map(transportista => 
                        <option key={'transportista-' + transportista.id} value={transportista.id}>
                            {transportista.nombre}
                        </option>
                    )}
                </select>
            </div>

            <CustomDatePicker 
                value={fecha}
                disableFuture={false}
                disablePast={false}
                onChangeDate={(date) => setFecha(date)}
            />

            <div className='w-100 d-flex align-items-center justify-content-stretch gap-3'>
                <button className="flex-grow-1 btn btn-info mt-3 mt-md-0 ms-md-3" onClick={() => handleFilter()}>
                    <FilterAlt />
                </button>

                <button className="flex-grow-1 btn btn-danger mt-3 mt-md-0" onClick={() => onFilterChange(null)}>
                    <FilterAltOff />
                </button>
            </div>
        </div>
    );
  
    const renderMassEditButtons = () => (
      <>
        {massEditMode ? (
          <>
            <button
              className="btn btn-primary"
              style={{ backgroundColor: '#CB4335' }}
              onClick={onToggleMassEditMode}
            >
              Cancelar
            </button>
            <button
              className="btn btn-success ms-0 ms-sm-3"
              onClick={onExecuteMassiveAction}
              disabled={selectedPedidos?.length === 0}
            >
              Seleccionar
            </button>
          </>
        ) : (
          <button
            className="flex-grow-1 flex-md-grow-0 btn btn-primary ms-3"
            onClick={onToggleMassEditMode}
          >
            Acción Masiva
          </button>
        )}
      </>
    );

    // TODO: quitamos admin de acciones masivas

    return (
      <div className="d-flex flex-column justify-content-start justify-content-sm-between mb-3 mt-2">
          <div className='w-100 d-flex flex-wrap flex-md-nowrap align-items-center justify-content-between justify-content-md-start mb-3'>
              <div className='flex-grow-1 d-flex flex-wrap flex-md-nowrap gap-3 align-items-center justify-content-between justify-content-md-start mb-3 mb-md-0'>
                  <span className='flex-grow-1 flex-md-grow-0'>
                      <span className='fs-6 text'>
                      <FontAwesomeIcon icon={faBook} size="1x" color="#215732" />
                      </span>
                      <small className='ms-1'>Flyer</small>
                  </span>
                  <span className='flex-grow-1 flex-md-grow-0'>
                      <span className='fs-6 text'>
                      <FontAwesomeIcon icon={faTape} size="1x" color="#359AD2" />
                      </span>
                      <small className='ms-1'>Cinta BParts</small>
                  </span>
                  <span className='flex-grow-1 flex-md-grow-0'>
                      <span className='fs-6 text'>
                      <FontAwesomeIcon icon={faTape} size="1x" color="#1A2F52" />
                      </span>
                      <small className='ms-1'>Cinta ServiceBox</small>
                  </span>
                  <span className='flex-grow-1 flex-md-grow-0'>
                      <span className='fs-6 text'>
                      <FontAwesomeIcon icon={faQrcode} size="1x" color="#215732" />
                      </span>
                      <small className='ms-1'>Pegatina QR piezas</small>
                  </span>
              </div>

              { (tabId !== 'individual' && tabId !== 'cancelacion') &&
              <div className='flex-grow-1 flex-md-grow-0'>
                <select 
                  name='estado_id' id='estado_id' 
                  value={filterStatus}
                  onChange={(e) => onChangeStatus(parseInt(e.target.value))}
                >
                  { (tabId === 'general') ? estados.filter(e => e.id <= 3)
                    .map(estado => <option key={'estado-' + estado.id} value={estado.id}>{estado.nombre}</option>)
                    :
                    estados.filter(e => e.id >= 4)
                    .map(estado => <option key={'estado-' + estado.id} value={estado.id}>{estado.nombre}</option>)
                  }
                  { (tabId === 'general') ?
                    <option value={0}>TODOS</option>
                    :
                    <option value={-1}>TODOS</option>
                  }
                </select>
              </div>
            }
          </div>

          <div className='w-100 flex-md-grow-0 d-flex flex-wrap flex-lg-nowrap align-items-end justify-content-between justify-md-content-end'>
            <div className={'flex-grow-1 flex-md-grow-0 d-flex flex-wrap flex-lg-nowrap align-items-center justify-content-stretch mt-3 mt-md-0 me-md-3'}>
              {!massEditMode && (
                <button className={"flex-grow-1 flex-md-grow-0 btn btn-primary"} onClick={onToggleFilters}>
                  {filtersVisible ? 'Ocultar Filtros' : 'Filtrar'}
                </button>
              )}
              {!filtersVisible && renderMassEditButtons()}
            </div>
            {filtersVisible && renderFilterControls()}
          </div>  
      </div>
  )
}
