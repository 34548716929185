import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    fetchDashboardPantalla,
    fetchNewRecord,
    fetchRefreshPantalla,
} from '../../../../redux/features/dashboardPantalla';
import EachSquareResults from './EachSquareResults';
import DashboardCharts from './DashboardCharts';
import './dashboardPantalla.scss';

const DashboardPantalla = () => {
    const { current, records, parts, ordersByStatus } = useSelector((state) => {
        return state.dashboard_pantalla;
    });
    const dispatch = useDispatch();
    const minutos = 15;
    useEffect(() => {
        if (!current && !records && !parts) {
            dispatch(fetchDashboardPantalla());
        } else {
            dispatch(fetchRefreshPantalla(true));
        }
        const interval = setInterval(() => {
            dispatch(fetchRefreshPantalla());
        }, minutos * 60 * 1000);

        return () => clearInterval(interval);
    }, []);

    useEffect(() => {
        if (current && records) {
            const { pedidosHoy, pedidosSemana, pedidosMes } = current;
            const { recordDia, recordSemana, recordMes } = records;
            if (
                pedidosHoy > recordDia.total_pedidos ||
                pedidosSemana > recordSemana.total_pedidos ||
                pedidosMes > recordMes.total_pedidos
            ) {
                dispatch(fetchNewRecord(current, records));
            }
        }
    }, [current]);

    return (
        <div className="dashboard-pantalla">
            <h2 className='dashboard-pantalla__total-pedidos'>TOTAL PEDIDOS</h2>
            {current && (
                <div className="dashboard-pantalla__current-week">
                    <EachSquareResults
                        title="SEMANA"
                        results={current.pedidosSemana}
                    />
                    <EachSquareResults
                        title="HOY"
                        results={current.pedidosHoy}
                        className="hoy"
                    />
                    <EachSquareResults
                        title="MES"
                        results={current.pedidosMes}
                    />
                </div>
            )}
            {records && (
                <div className="dashboard-pantalla__records">
                    <EachSquareResults
                        title="SEMANA RECORD"
                        results={records.recordSemana.total_pedidos}
                        extraInfo={records.recordSemana.semana}
                    />
                    <EachSquareResults
                        title="DIA RECORD"
                        results={records.recordDia.total_pedidos}
                        extraInfo={records.recordDia.dia}
                    />
                    <EachSquareResults
                        title="MES RECORD"
                        results={records.recordMes.total_pedidos}
                        extraInfo={records.recordMes.mes}
                    />
                </div>
            )}
            {ordersByStatus && (
                <div className="dashboard-pantalla__status">
                    <EachSquareResults
                        title="PENDIENTES DESCARGA"
                        results={ordersByStatus.totalPendientesDescarga}
                        className={ordersByStatus.totalPendientesDescarga > 40 ? 'red-color' : (ordersByStatus.totalPendientesDescarga < 20 ? 'green-color' : '')}
                    />
                    <EachSquareResults
                        title="DESCARGADOS"
                        results={ordersByStatus.totalDescargados}
                        className={ordersByStatus.totalDescargados > 40 ? 'red-color' : (ordersByStatus.totalDescargados < 20 ? 'green-color' : '')}
                    />
                    <EachSquareResults
                        title="ASIGNADOS"
                        results={ordersByStatus.totalAsignados}
                        className={ordersByStatus.totalAsignados > 40 ? 'red-color' : (ordersByStatus.totalAsignados < 20 ? 'green-color' : '')}
                    />
                </div>
            )}
            {parts && <DashboardCharts data={parts} />}
        </div>
    );
};

export default DashboardPantalla;
