
import { createBrowserRouter } from 'react-router-dom';
import ProtectedRoute from './ProtectedRoute';
import Main from '../views/Main';
import React from 'react';

import Dashboard from '../views/Main/components/Dashboard';
import MiCuenta from '../views/Main/components/Mi-cuenta';
import Recogida from '../views/Main/components/Recogida';
import Carrier from '../views/Main/components/Carrier';
import Vin from '../views/Main/components/Vin';
import Usuarios from '../views/Main/components/Usuarios';
import Sidegal from '../views/Main/components/Sidegal';
import Ajustes from '../views/Main/components/Ajustes';
import PiezasSidegal from '../views/Main/components/VinPiezasSidegal';
import DashboardPantalla from '../views/Main/components/DashboardPantalla';
import Segohon from '../views/Main/components/Segohon';


export const router = createBrowserRouter([
    {
        path: '/',
        element: <ProtectedRoute ><Main /></ProtectedRoute>,
        loader: ({ request }) => {
            return null
        },
        shouldRevalidate: () => true,
        children: [
            {path:'/', element:<Dashboard/>},
            {path:'/ajustes', element:<Ajustes/>},
            {path:'/recogida', element:<Recogida/>},
            {path:'/vin', element:<Vin/>},
            {path:"/vin-piezas-sidegal", element: <PiezasSidegal/>},
            {path:'/carrier', element:<Carrier/>},
            {path:'/mi-cuenta', element:<MiCuenta/>},
            {path:'/usuarios', element:<Usuarios/>},
            {path:'/sidegal', element:<Sidegal/>},
            {path:'/segohon', element:<Segohon/>},
            {path:'/dashboard-pantalla', element:<DashboardPantalla/>}
        
        ]
    }
]);
