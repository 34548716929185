import { toast } from "react-toastify";
import { asyncMac, makeAsyncTypes, makeFetchingReducer } from "../utils";
import { API_PIEZAS_SEGOHON } from "../../views/Main/components/shared/services/segohon";

const initiaSegohon = [];

export const segohonReducer = (state = initiaSegohon, action) => {
    switch(action.type){
        case "piezas_segohon/fullfilled": {
            return action.payload;
        }
        default:
            return state
    }
}

const asyncSidegal = makeAsyncTypes('piezas_segohon');
const [setPending, setFullFilled, setError ] = asyncMac(asyncSidegal);

export const segohonFetchingReducer = makeFetchingReducer([
    'piezas_segohon/pending', 
    'piezas_segohon/fullfilled', 
    'piezas_segohon/rejected'
]);

export const fetchPiezasSegohon = (page, filters = null) => async dispatch => {
    dispatch({type: 'loading/set', payload: true});
    dispatch(setPending());

    try {
        const response = await API_PIEZAS_SEGOHON.getPiezasSegohon(page, filters);
        const data = await response.data;
        dispatch(setFullFilled(data));
        dispatch({type: 'loading/set', payload: false});
    } catch (e) {
        dispatch({type: 'loading/set', payload: false});
        dispatch(setError(e.message))
        toast.error(e.message)
    }
}

export const segohonStatusReducer = segohonFetchingReducer