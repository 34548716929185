import API from '../../../../../services/api';

export const API_PIEZAS_SEGOHON = {
    getPiezasSegohon: async (page, filters) => {
        const query = '?page=' + page;
        let filtersQuery = '';
    
        if(filters) {
            if(filters?.id && filters.id !== null && filters.id !== '') filtersQuery += '&id_referencia_plataforma=' + filters.id;
        }

        return await API.get(`/segohon${query}${filtersQuery}`).then((res) => res.data);
    },
    saveUbicacion: async (data) => {
        return await API.post(`/segohon`, data).then((res) => res.data);
    }
};
