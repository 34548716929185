import Keycloak from 'keycloak-js';
import Config from '../config';

const keycloak = new Keycloak({
    url: Config.keycloak.url,
    realm: Config.keycloak.realm,
    clientId: Config.keycloak.clientId,
});

export default keycloak;
