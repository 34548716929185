import API from '../../../../../services/api';

export const API_ETIQUETAS = {
    getPdfEtiqueta: async (type, pedidoId = null, envioId = null) => {
        return await API.get(
            `/etiqueta?type=` +
                type +
                '&pedido_id=' +
                pedidoId +
                (envioId ? '&envio_id=' + envioId : '')
        ).then((res) => res.data);
    },
    getOvokoEtiqueta: async (codigoPedido, numPresupuesto) => {
        return await API.post('/etiqueta/ovoko', { codigoPedido, numPresupuesto })
            .then((res) => res.data)
            .catch((error) =>
                console.error('Error obteniendo etiqueda de Ovoko:', error)
            );
    },
};
