import { useKeycloak } from '@react-keycloak/web';
import PropTypes from 'prop-types';
import API from '../../services/api';
import { Navigate, useLocation } from 'react-router-dom';
import User from '../../models/user';
import { getRoleById, getRoleByName} from '../../models/roles';
import { SidebarData } from '../../views/Main/components/shared/components/Navbar/SidebarData';

const ProtectedRoute = ({ children }) => {
    const { keycloak, initialized } = useKeycloak();
    const location = useLocation();
    
    if (!initialized) {
        return <div></div>;
    }

    if (!keycloak.authenticated) {
        keycloak.login()
        return null;
    }
    
    const currentUser = new User(keycloak.token, keycloak.tokenParsed);
    const route = SidebarData.find(route => route.path === location.pathname);
    const canAccess = currentUser.roles.some(role => {
        const userRole = getRoleByName(role)
        return  route.permission.includes(userRole.id)
    })

    if(!canAccess){
        const allowedRoute = SidebarData.find(item => {
            return item.permission.some(role => {
                const roleInfo = getRoleById(role)
                return currentUser.roles.includes(roleInfo.name)
        })})?.path || "/"
        return <Navigate to={allowedRoute} replace />
    }
    
    //TODO: Review this context
    // if(keycloak.authenticated) {
    //     const user = new User(keycloak.token, keycloak.tokenParsed)
    //     setToken(user);
    // }

    if (keycloak?.token) {

        API.interceptors.request.use(
            (config) => {
                config.headers['Authorization'] = `Bearer ${keycloak.token}`;
                return config;
            },
            (error) => Promise.reject(error)
        );

        API.interceptors.response.use(
            (response) => {
                return response;
            },
            (error) => {
                if (error.response && error.response?.status === 401) {
                    keycloak.logout();
                }
                return Promise.reject(error);
            }
        );
    }
    return children;
};

ProtectedRoute.propTypes = {
    children: PropTypes.any.isRequired,
};

export default ProtectedRoute;
