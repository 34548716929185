import API from '../../../../../services/api';
import { headers } from '../helpers/functionalities';

export const API_PEDIDOS = {
    getPedidos: async (page, status, userId, filters) => {
        const query = '?page=' + page + '&status=' + status + (userId ? '&user_id=' + userId : '')
        let filtersQuery = '';
    
        if(filters) {
            if(filters?.id && filters.id !== null && filters.id !== '') filtersQuery += '&id_referencia_plataforma=' + filters.id;
            if(filters?.numero_presupuesto && filters.numero_presupuesto !== null && filters.numero_presupuesto !== '')  filtersQuery += '&presupuesto=' + filters.numero_presupuesto;
            if(filters?.fecha && filters.fecha !== null && filters.fecha !== '') filtersQuery += '&fecha=' + filters.fecha;
            if(filters?.transportista_id && filters.transportista_id !== null && filters.transportista_id !== '' && filters.transportista_id !== -1) filtersQuery += '&transportista_id=' + filters.transportista_id;
            if(filters?.reload && filters.reload === 1) filtersQuery += '&reload=1';
        }
    
        return await API.get(`/pedidos${query}${filtersQuery}`)
        .then(res => res.data);
    },
    getPedidoByNumPresu: async (numPresu) => {
        return await API.get(`/pedidos/${numPresu}`)
        .then((res) => res.data);
    },
    getPedidoByIdRefPieza: async (idRef) => {
        return await API.get(`/pedidos/referencia_pieza/${idRef}`)
        .then((res) => res.data);
    },
    getAlbaranInfo: async (pedidoId, idRefPieza
    ) => {
        return await API.get(`/pedidos/${pedidoId}/${idRefPieza}/albaran`)
        .then((res) => res.data);
    },
    saveRecogida: async (recogida) => {
        return await API.post(`/pedidos/recogida`, recogida)
        .then((res) => res.data);
    },
    uploadEtiqueta: async (etiqueta, pedidoId) => {
        return await API.post(`/pedidos/${pedidoId}/etiqueta`, etiqueta, headers)
        .then((res) => res.data);
    },
    cancelarPiezas: async (piezas, pedidoId) => {
        return await API.post(`/pedidos/${pedidoId}/cancelar_piezas`, piezas)
        .then((res) => res.data);
    },
    eliminarEtiquetaEnvio: async (pedidoId) => {
        return await API.post(`/pedidos/${pedidoId}/eliminar_etiqueta`, {})
        .then((res) => res.data);
    },
    enviarEmailCambioTransportistaRF: async (pedidoId) => {
        return await API.post(`/pedidos/${pedidoId}/rf_cambio_transportista`, {})
        .then((res) => res.data);
    },
    updatePedido: async (id, pedido) => {
        return await API.put(`/pedidos/${id}`, pedido)
        .then((res) => res.data);
    },
    updateTransportistaPedido: async (id, data) => {
        return await API.put(`/pedidos/${id}/transportista`, data)
        .then((res) => res.data);
    }
};
