import { Outlet } from "react-router-dom";
import Config from './components/shared/components/Config';
import Navbar from './components/shared/components/Navbar';
import { ToastContainer } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import LoadingBackdrop from './components/shared/components/LoadingBackdrop';
import NewVersionModal from './components/shared/components/NewVersionModal';
import { fetchReadOnlyData } from '../../redux/readOnly';
import 'react-toastify/dist/ReactToastify.css';
import { useKeycloak } from '@react-keycloak/web';
import User from '../../models/user'

const Main = ({ onLogout, userRol }) => {
  const dispatch = useDispatch();
  const loading = useSelector(state => {
      return state.loading
  })
  const reload = useSelector(state => {
      return state.reload
  })

  const { keycloak } = useKeycloak();
  const currentUser = new User(keycloak.token, keycloak.tokenParsed);

  useEffect(() => {
    // MAKE INITIAL LOAD OF READ ONLY DATA ON REDUX STORE AND CHECK IF IS SAVED IN LOCALSTORAGE
    if(localStorage.getItem('read_only')) {
        const data = JSON.parse(localStorage.getItem('read_only'))
        dispatch({type: 'roles/fullfilled', payload: data.roles});
        dispatch({type: 'plataformas/fullfilled', payload: data.plataformas});
        dispatch({type: 'cdn_services/fullfilled', payload: data.cdn_services});
        dispatch({type: 'hosts/fullfilled', payload: data.hosts});
        dispatch({type: 'estados/fullfilled', payload: data.estados});
        dispatch({type: 'tipo_origenes/fullfilled', payload: data.tipo_origenes});
        dispatch({type: 'actualizaciones/fullfilled', payload: data.actualizaciones});
        dispatch({type: 'users/fullfilled', payload: data.users});
        dispatch({type: 'transportistas/fullfilled', payload: data.transportistas});
        dispatch({type: 'datos_transportistas/fullfilled', payload: data.datosTransportistas});
    } else {
        dispatch(fetchReadOnlyData());
    }
  }, []);

  return (
    <div className="dashboard-container">
      <Navbar onLogout={onLogout} userRol={currentUser.roles} />
      <Config />

      <Outlet/>

      { loading && <LoadingBackdrop /> }
      { reload && <NewVersionModal /> }

      <ToastContainer
        position="bottom-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </div>
  );
};

export default Main;
