/* eslint-disable jsx-a11y/alt-text */
import { React, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { Status } from '../../../../../models/pedidoStatus';
import { setErrorMessage } from '../../shared/helpers/functionalities';
import Table from './Table';
import ConfirmModal from '../../shared/components/modals/Confirm';
import MassiveActionsModal from '../../shared/components/modals/MassiveActions';
import PdfViewerModal from '../../shared/components/modals/PdfViewer';
import PiezaModal from '../../shared/components/modals/PiezaInfo';
import DashboardService from '../../../../../services/dashboard';
import InfoModal from '../../shared/components/modals/Info';
import UserSelector from './UserSelector';
import EtiquetaForm from './EtiquetaForm';
import LanguageSelector from './LanguageSelector';
import LogsTable from './LogsTable';
import ReactDOMServer from "react-dom/server";
import html2pdf from 'html2pdf.js/dist/html2pdf.min';
import AlbaranES from '../../../../../services/pdf/templates/albaran/albaranES';
import AlbaranEN from '../../../../../services/pdf/templates/albaran/albaranEN';
import TransportistaSelector from './TransportistaSelector';
import PiezasSelector from './PiezasSelector';
import SincronizarUbicacion from './SincronizarUbicacion';
import { API_PEDIDOS } from '../../shared/services/pedidos';
import { API_ETIQUETAS } from '../../shared/services/etiquetas';
import Filters from './Filters';

export default function PedidosTable({ 
  id,
  page,
  filterStatus,
  filterValues,
  lastUpdated,
  pedidos,
  userId,
  isAdmin,
  onPageChange,
  onFilterChange,
  onStatusChange,
}) {
  const dispatch = useDispatch();
  const dashboard = useSelector(state => {
    return state.dashboard.entity
  }); 
  const [filteredPedidos, setFilteredPedidos] = useState([]);
  const [modalPiezaOpen, setModalPiezaOpen] = useState(false);
  const [modalUserOpen, setModalUserOpen] = useState(false);
  const [selectedPieza, setSelectedPieza] = useState(null);
  const [selectedPedido, setSelectedPedido] = useState(null);
  const [pdfViewerOpen, setPdfViewerOpen] = useState(false);
  const [massEditMode, setMassEditMode] = useState(false);
  const [selectedPedidos, setSelectedPedidos] = useState([]);
  const [filtersVisible, setFiltersVisible] = useState(false);
  const [selectAllChecked, setSelectAllChecked] = useState(false);
  const [modalMassiveActionsOpen, setModalMassiveActionsOpen] = useState(false);

  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const [confirmDesasignModalOpen, setConfirmDesasignModalOpen] = useState(false);
  const [confirmModalAction, setConfirmModalAction] = useState(null);
  const [confirmModalPedido, setConfirmModalPedido] = useState(null);
  const [uploadModalOpen, setUploadModalOpen] = useState(false);
  const [openSelectAlbaranLanguage, setOpenSelectAlbaranLanguage] = useState(false);
  const [openLogs, setOpenLogs] = useState(false);
  const [openAssignTransportista, setOpenAssignTransportista] = useState(false);
  const [openCancelarPiezas, setOpenCancelarPiezas] = useState(false);
  const [openConfirmDeleteEtiqueta, setOpenConfirmDeleteEtiqueta] = useState(false);
  const [openSincronizarUbicacion, setOpenSincronizarUbicacion] = useState(false);
  const [openConfirmEliminar, setOpenConfirmEliminar] = useState(false);
  const [openConfirmCambioTransportistaRF, setOpenConfirmCambioTransportistaRF] = useState(false);

  useEffect(() => {
    if(pedidos) {
      setFilteredPedidos(pedidos);
    }
  }, [lastUpdated, pedidos, filterStatus, filterValues]);

  const handleChangePage = (event, newPage) => {
    onPageChange(newPage);
  };

  const getPaginationCount = () => {
    const isFilterEmpty = Object.values(filterValues).every(x => x === null || x === '' || x === -1 || (x === 1 || x === 0 ? true : false));
    const total = isFilterEmpty ? (dashboard?.pedidos?.count ? dashboard?.pedidos?.count : 1) : filteredPedidos.length;
    var totalPages = total < 15 ? 1 : Math.ceil(total / 15);
    return parseInt(totalPages);
  }

  const handleOpenModalPieza = (pieza) => {
    setSelectedPieza(pieza);
    setModalPiezaOpen(true);
  };

  const handleOpenAsignarPedido = (pedido) => {
    setSelectedPedido(pedido);
    setModalUserOpen(true);
  };

  const handleOpenDesasignarPedido = (pedido) => {
    setSelectedPedido(pedido);
    setConfirmDesasignModalOpen(true);
  }

  const handleOpenModalMassiveActions = () => {
    setModalMassiveActionsOpen(true);
  };

  const handleCloseModalMassiveActions = () => {
    setModalMassiveActionsOpen(false);
  };

  const handlePreviewEtiqueta = (pedido) => {
    setSelectedPedido(pedido);
    setPdfViewerOpen(true);
  };

  const handleClosePdfViewer = () => {
    setSelectedPedido(null);
    setPdfViewerOpen(false);
  };

  const handleCloseModalPieza = () => {
    setModalPiezaOpen(false);
    setSelectedPieza(null);
  };

  const handleCloseModalUser = () => {
    setModalUserOpen(false);
    setSelectedPedido(null);
  };

  const handleConfirmAction = (confirm) => {
    setConfirmModalOpen(false);
    if (confirm && confirmModalAction && confirmModalPedido) {
      executeAction(confirmModalAction, confirmModalPedido);
    }
  };

  const handleImprimirEtiqueta = async(pedido) => {
    dispatch({type: 'loading/set', payload: true});
    const etiquetas = await DashboardService.handleImprimirEtiqueta(pedido)
    .catch(function (error) {
      dispatch({type: 'loading/set', payload: false});
      toast.error(setErrorMessage(error))
    });

    dispatch({type: 'loading/set', payload: false});
  }

  const handleSolicitarEtiqueta = async(pedido) => {
    dispatch({type: 'loading/set', payload: true});
    await DashboardService.handleSolicitarEtiqueta(
      pedido
    ).catch(function (error) {
      dispatch({type: 'loading/set', payload: false});
      toast.error(setErrorMessage(error))
    });
    dispatch({type: 'loading/set', payload: false});
  }
  
  const handleGetOvokoLabel = async(pedido) => {
    dispatch({type: 'loading/set', payload: true});
    const response = await API_ETIQUETAS.getOvokoEtiqueta(pedido.codigo_pedido, pedido.numero_presupuesto)
      .catch((error) => {
        toast.error(setErrorMessage(error))
      }).finally(()=> {
        dispatch({type: 'loading/set', payload: false});
      })
    if(response && response.success) {
      toast.success(response.message);
      onFilterChange(page);
    }
  }

  const handleOpenCancelarPiezas = (pedido) => {
    setSelectedPedido(pedido);
    setOpenCancelarPiezas(true);
  }
  const handleCloseCancelarPiezas = () => {
    setOpenCancelarPiezas(false);
    setSelectedPedido(null);
  }
  const handleCancelarPiezas = async(piezas) => {
    dispatch({type: 'loading/set', payload: true});
    const response = await API_PEDIDOS.cancelarPiezas(piezas, selectedPedido.id)
    .catch(function (error) {
      dispatch({type: 'loading/set', payload: false});
      toast.error(setErrorMessage(error.message))
    });

    if(response && response.success) {
      toast.success(response.message);
      dispatch({type: 'loading/set', payload: false});
      handleCloseCancelarPiezas();
      onFilterChange(page);
    }
  }

  const handleOpenConfirmDeleteEtiqueta = (pedido) => {
    setSelectedPedido(pedido);
    setOpenConfirmDeleteEtiqueta(true);
  }
  const handleCloseDeleteEtiqueta = () => {
    setOpenConfirmDeleteEtiqueta(false);
    setSelectedPedido(null);
  }
  const handleDeleteEtiqueta = async(confirm) => {
    if(confirm) {
      dispatch({type: 'loading/set', payload: true});
      const response = await API_PEDIDOS.eliminarEtiquetaEnvio(selectedPedido.id)
      .catch(function (error) {
        dispatch({type: 'loading/set', payload: false});
        toast.error(setErrorMessage(error.message))
      });

      if(response && response.success) {
        toast.success(response.message);
        dispatch({type: 'loading/set', payload: false});
        handleCloseDeleteEtiqueta();
        onFilterChange(page);
      }
    }

    handleCloseDeleteEtiqueta();
  }

  const executeAction = (action, pedido) => {
    if (action === 'imprimir_etiqueta') {
      handleImprimirEtiqueta(pedido);
    } else if (action === 'preview_etiqueta') {
      handlePreviewEtiqueta(pedido);
    } else if (action === 'remove_etiqueta') {
      handleOpenConfirmDeleteEtiqueta(pedido);
    } else if (action === 'pedido_descargado') {
      handleDescargarPedido(pedido);
    } else if (action === 'pedido_rechazado') {
      handleOpenConfirmEliminarPedido(pedido);
    } else if (action === 'pedido_cancelado') {
      handleCancelPedido(pedido);
    } else if (action === 'pedido_completado') {
      handlePedidoCompletado(pedido);
    } else if (action === 'recuperar_pedido') {
      const lastLogBeforeCancel = pedido.logs[pedido.logs.length - 2];
      actualizarPedido(pedido, pedido.user_id, lastLogBeforeCancel.estado_id);
    } else if (action === 'asignar_pedido') {
      handleOpenAsignarPedido(pedido);
    } else if (action === 'desasignar_pedido') {
      handleOpenDesasignarPedido(pedido);
    } else if (action === 'solicitar_etiqueta') {
      handleSolicitarEtiqueta(pedido);
    } else if (action === 'subir_etiqueta') {
      handleOpenUploadModal(pedido)
    } else if (action === 'logs') {
      handleOpenLogs(pedido)
    } else if(action === 'asignar_transportista') {
      handleOpenAssignTransportista(pedido)
    } else if(action === 'cancelar_piezas') {
      handleOpenCancelarPiezas(pedido)
    } else if(action === 'sincronizar_ubicacion') {
      handleOpenSincronizarUbicacion(pedido)
    } else if(action === 'obtener_etiqueta_ovoko') {
      handleGetOvokoLabel(pedido)
    } else if(action === 'rf_cambio_transportista') {
      handleOpenConfirmCambioTransportistaRF(pedido)
    }
  };

  const handleAction = (action, pedido) => {
    if (
      ['pedido_rechazado', 'pedido_completado', 'asignar_pedido'].includes(action) &&
      pedido.piezas.length > 1
    ) {
      setConfirmModalAction(action);
      setConfirmModalPedido(pedido);
      setConfirmModalOpen(true);
    } else {
      executeAction(action, pedido);
    }
  };

  const handleOpenLogs = (pedido) => {
    setSelectedPedido(pedido);
    setOpenLogs(true);
  }
  const handleCloseLogs = () => {
    setOpenLogs(false);
    setSelectedPedido(null);
  }

  const handleOpenUploadModal = (pedido) => {
    setSelectedPedido(pedido);
    setUploadModalOpen(true);
  }
  const handleCloseUploadModal = () => {
    setUploadModalOpen(false);
    setSelectedPedido(null);
  }

  const handleOpenSincronizarUbicacion = (pedido) => {
    setOpenSincronizarUbicacion(true);
    setSelectedPedido(pedido);
  }
  const handleCloseSincronizarUbicacion = () => {
    setOpenSincronizarUbicacion(false);
    setSelectedPedido(null);
  }
  const submitSincronizarUbicacion = (orderId, partId, newUbicacion) => {
      setFilteredPedidos((prev) =>
          prev.map((order) =>
              order.id === orderId
                  ? {
                        ...order,
                        piezas: order.piezas.map((part) =>
                            part.id === partId
                                ? { ...part, ubicacion: newUbicacion }
                                : part
                        ),
                    }
                  : order
          )
      );
  };

  const handleSaveEtiqueta = async(data) => {
    dispatch({type: 'loading/set', payload: true});
    const response = await API_PEDIDOS.uploadEtiqueta(data, selectedPedido.id)
    .catch(function (error) {
      dispatch({type: 'loading/set', payload: false});
      toast.error(setErrorMessage(error.message))
    });

    if(response && response.success) {
      dispatch({type: 'loading/set', payload: false});
      toast.success(response.message);
      handleCloseUploadModal();
      onFilterChange(page);
    }
  }

  const handleOpenAssignTransportista = (pedido) => {
    setSelectedPedido(pedido);
    setOpenAssignTransportista(true);
  }
  const handleCloseAssignTransportista = () => {
    setOpenAssignTransportista(false);
    setSelectedPedido(null);
  }
  const handleAssignTransportista = async(transportistaId) => {
    dispatch({type: 'loading/set', payload: true});
    const response = await API_PEDIDOS.updateTransportistaPedido(selectedPedido.id, {
      transportista_id: transportistaId
    }).catch(function (error) {
      dispatch({type: 'loading/set', payload: false});
      toast.error(setErrorMessage(error.message))
    });

    if(response && response.success) {
      dispatch({type: 'loading/set', payload: false});
      toast.success(response.message);
      handleCloseAssignTransportista();
      onFilterChange(page);
    }
  }

  const crearAlbaranPedido = async(pedido, idRefPieza, language) => {
    dispatch({type: 'loading/set', payload: true});
    const response = await API_PEDIDOS.getAlbaranInfo(pedido.id, idRefPieza)
    .catch(function (error) {
      dispatch({type: 'loading/set', payload: false});
      toast.error(setErrorMessage(error))
    });

    if(response && response.success) {
      dispatch({type: 'loading/set', payload: false});

      const pdfTemplate = language === 'es' ? AlbaranES(response.data) 
        : AlbaranEN(response.data);

      const printElement = ReactDOMServer.renderToString(pdfTemplate);
      html2pdf().from(printElement).save(`albaran_${pedido.numero_presupuesto}.pdf`);

      toast.success('Albaran generado correctamente!')
      handleCloseSelectAlbaranLanguage();
    } else {
      toast.error('Ups! Algo ha ido mal...')
    }
  }

  const handleOpenConfirmEliminarPedido = (pedido) => {
    setOpenConfirmEliminar(true);
    setSelectedPedido(pedido);
  }
  const handleCloseConfirmEliminarPedido = () => {
    setOpenConfirmEliminar(false);
    setSelectedPedido(null);
  }
  const handleConfirmEliminarPedido = (confirm) => {
    if(confirm) {
      handleEliminarPedido(selectedPedido, id === 'general' || id === 'individual' ? false : true)
    }

    handleCloseConfirmEliminarPedido();
  }
  const handleEliminarPedido = (pedido, isForDelete) => {
    actualizarPedido(
      pedido,
      userId,
      Status.CANCELADO.id,
      isForDelete
    );
  };

  const handleOpenConfirmCambioTransportistaRF = (pedido) => {
    setSelectedPedido(pedido);
    setOpenConfirmCambioTransportistaRF(true);
  }
  const handleCloseConfirmCambioTransportistaRF = () => {
    setSelectedPedido(null);
    setOpenConfirmCambioTransportistaRF(false);
  }
  const handleConfirmCambioTransportistaRF = async(confirm) => {
    if(confirm) {
      dispatch({type: 'loading/set', payload: true});
      const response = await API_PEDIDOS.enviarEmailCambioTransportistaRF(selectedPedido.id)
      .catch(function (error) {
        dispatch({type: 'loading/set', payload: false});
        toast.error(setErrorMessage(error))
      });

      if(response && response.success) {
        dispatch({type: 'loading/set', payload: false});
        toast.success(response.message);
      }
    }

    handleCloseConfirmCambioTransportistaRF();
  }

  const handleCancelPedido = (pedido) => {
    actualizarPedido(
      pedido,
      userId,
      Status.PENDIENTE_DE_CANCELACION.id
    );
  }

  const handleDescargarPedido = (pedido) => {
    actualizarPedido(
      pedido,
      userId,
      Status.DESCARGADO.id
    );
  }

  const handlePedidoCompletado = (pedido) => {
    actualizarPedido(
      pedido,
      userId,
      Status.COMPLETADO.id
    );
  };

  const handleAsignarUsuario = (userId) => {
    actualizarPedido(
      selectedPedido,
      userId,
      Status.ASIGNADO.id
    );
    setSelectedPedido(null);
  };

  const handleConfirmDesasignAction = async(confirm) => {
    if(confirm) {
      actualizarPedido(
        selectedPedido,
        null,
        selectedPedido.estado_id
      );
    }

    setSelectedPedido(null);
    setConfirmDesasignModalOpen(false);
  }

  const actualizarPedido = async (pedido, userId, estadoId, isForDelete = null) => {
    try {
      dispatch({type: 'loading/set', payload: true});
      const body = {
        user_id: userId,
        estado_id: estadoId,
        isForDelete: isForDelete
      };
      
      const response = await API_PEDIDOS.updatePedido(pedido.id, body)
      .catch(function (error) {
        dispatch({type: 'loading/set', payload: false});
        toast.error(setErrorMessage(error))
      });

      if (response.success) {
        toast.success(response.message);
        dispatch({type: 'loading/set', payload: false});
        handleCloseModalUser();
        onFilterChange(1)
      }
    } catch (error) {
      console.error('Error updating pedido:', error);
    }
  };

  const clearFilters = () => {
    onFilterChange(page, null);
  };

  const toggleMassEditMode = () => {
    setMassEditMode(!massEditMode);
    if (massEditMode) {
      setSelectAllChecked(false);
      setSelectedPedidos([]);
    }
  };

  const handleSelectPedido = (pedido) => {
    if (selectedPedidos.find(p => p.origin_id === pedido.origin_id)) {
      setSelectedPedidos(selectedPedidos.filter(p => p.origin_id !== pedido.origin_id));
    } else {
      setSelectedPedidos([...selectedPedidos, pedido]);
    }
  };

  const handleExecuteMassiveAction = () => {
    // Open the modal to select the massive action
    handleOpenModalMassiveActions();
  };

  const handleFinalizeMassiveAction = async (options) => {
    dispatch({type: 'loading/set', payload: true});
    console.log('Selected Massive Action:', options);

    if (options.imprimir) {
      const selectedPedidosToPrint = pedidos.filter((pedido) =>
        selectedPedidos.find(p => p.origin_id === pedido.origin_id)
      );
      await DashboardService.handleImprimirEtiquetaMassiva(
        selectedPedidosToPrint
      );
    }

    if (
      options.marcar.completado ||
      options.marcar.rechazado ||
      options.assignar.habilitado
    ) {
      const estado = options.marcar.completado
        ? Status.COMPLETADO.id
        : options.marcar.rechazado
        ? Status.CANCELADO.id // RECHAZADO
        : Status.ASIGNADO.id // ASIGNADO;

      for (const pedidoInfo of selectedPedidos) {
        const pedido = pedidos.find((pedido) => pedido.origin_id === pedidoInfo.origin_id);
        if (pedido) {
          const idUser = options.assignar.habilitado
            ? options.assignar.user
            : userId;
          await actualizarPedido(pedido, idUser, estado);
        }
      }
      setSelectedPedidos([]);
      setMassEditMode(false);
    }

    dispatch({type: 'loading/set', payload: false});
    setModalMassiveActionsOpen(false);
    setSelectAllChecked(false);
    onFilterChange(page)
  };

  const toggleFilters = () => {
    setFiltersVisible(!filtersVisible);

    if(filtersVisible == true) {
      const isFilterEmpty = Object.values(filterValues).every(x => x === null || x === '');

      if(!isFilterEmpty) clearFilters(); // Clear filters when toggling if are filled
    }
  };

  const handleSelectAll = (event) => {
    const checked = event.target.checked;
    setSelectAllChecked(checked);
    if (checked) {
      const allPedidoIds = filteredPedidos.map((pedido) => pedido.origin_id);
      setSelectedPedidos(allPedidoIds);
    } else {
      setSelectedPedidos([]);
    }
  };

  const handleOpenSelectAlbaranLanguage = (pedido, pieza) => {
    setSelectedPedido(pedido);
    setSelectedPieza(pieza);
    setOpenSelectAlbaranLanguage(true);
  }
  const handleCloseSelectAlbaranLanguage = () => {
    setOpenSelectAlbaranLanguage(false);
    setSelectedPedido(null);
    setSelectedPieza(null);
  }
  const handelSelectAlbaranLanguage = (language) => {
    crearAlbaranPedido(selectedPedido, selectedPieza.id_referencia_plataforma, language)
  }
  

  return (
    <div className="w-100">
      <div className='w-100'>
        <Filters 
          tabId={id}
          filterStatus={filterStatus}
          filterValues={filterValues}
          filtersVisible={filtersVisible}
          selectedPedidos={selectedPedidos}
          massEditMode={massEditMode}
          isAdmin={isAdmin}
          onFilterChange={(filters) => onFilterChange(page, filters)}
          onToggleMassEditMode={toggleMassEditMode}
          onExecuteMassiveAction={handleExecuteMassiveAction}
          onToggleFilters={toggleFilters}
          onChangeStatus={onStatusChange}
        />

        <Table 
          id={id}
          page={page}
          filterStatus={filterStatus}
          filterValues={filterValues}
          pedidos={filteredPedidos}
          selectedPedidos={selectedPedidos}
          isAdmin={isAdmin}
          pageCount={getPaginationCount()}
          massEditMode={massEditMode}
          selectAllChecked={selectAllChecked}
          onPageChange={handleChangePage}
          onSelectPedido={handleSelectPedido}
          onSelectAllPedidos={handleSelectAll}
          onAction={handleAction}
          onOpenModalPieza={handleOpenModalPieza}
          onOpenSelectAlbaranLanguage={handleOpenSelectAlbaranLanguage}
        />

        { (modalPiezaOpen) &&
          <PiezaModal
            pieza={selectedPieza}
            state={modalPiezaOpen}
            width="sm"
            onClose={handleCloseModalPieza}
          />
        }

        { (modalUserOpen) &&
          <InfoModal
            title={"Selecciona el usuario al que quieres asignar el pedido"}
            content={
              <UserSelector
                rolId={2}
                onAssign={handleAsignarUsuario}
              />
            }
            state={modalUserOpen}
            width={"sm"}
            onClose={handleCloseModalUser}
          />
        }

        { (uploadModalOpen) &&
          <InfoModal
            title={"Subir etiqueta para pedido " + selectedPedido.numero_presupuesto}
            content={
              <EtiquetaForm 
                onSubmitEtiqueta={handleSaveEtiqueta}
              />
            }
            state={uploadModalOpen}
            width={"sm"}
            onClose={handleCloseUploadModal}
          />
        }

        { (openSelectAlbaranLanguage) &&
          <InfoModal
            title={"Imprimir albaran de entrega " + selectedPedido.numero_presupuesto}
            content={
              <LanguageSelector 
                onSelectLanguage={handelSelectAlbaranLanguage}
              />
            }
            state={openSelectAlbaranLanguage}
            width={"sm"}
            onClose={handleCloseSelectAlbaranLanguage}
          />
        }

        { (pdfViewerOpen) &&
          <PdfViewerModal
            pedido={selectedPedido}
            state={pdfViewerOpen}
            width="md"
            onClose={handleClosePdfViewer}
          />
        }

        { (modalMassiveActionsOpen) &&
          <MassiveActionsModal
            from={id}
            state={modalMassiveActionsOpen}
            selectedPedidos={selectedPedidos}
            isAdmin={isAdmin}
            width="sm"
            onClose={handleCloseModalMassiveActions}
            onReturn={(options) => handleFinalizeMassiveAction(options)}
          />
        }

        { (confirmModalOpen) &&
          <ConfirmModal
            title="Confirmar acción"
            description="Este pedido tiene más de una pieza, ¿desea continuar con la acción?"
            state={confirmModalOpen}
            onConfirmAction={handleConfirmAction}
            onClose={() => setConfirmModalOpen(false)}
          />
        }

        { (confirmDesasignModalOpen) &&
          <ConfirmModal
            title="Desasignar pedido"
            description="Estás seguro de desasignarte el pedido?"
            state={confirmDesasignModalOpen}
            onConfirmAction={handleConfirmDesasignAction}
            onClose={() => setConfirmDesasignModalOpen(false)}
          />
        }

        { (openConfirmDeleteEtiqueta) &&
          <ConfirmModal
            title={`Eliminar etiqueta del pedido ${selectedPedido.numero_presupuesto}`}
            description="Estás seguro de eliminar la etiqueta de envío?"
            state={openConfirmDeleteEtiqueta}
            onConfirmAction={handleDeleteEtiqueta}
            onClose={handleCloseDeleteEtiqueta}
          />
        }

        { (openConfirmEliminar) &&
          <ConfirmModal
            title={`Rechazar pedido ${selectedPedido.numero_presupuesto}?`}
            description="¿Estás seguro de rechazar el pedido?"
            state={openConfirmEliminar}
            onConfirmAction={handleConfirmEliminarPedido}
            onClose={handleCloseConfirmEliminarPedido}
          />
        }

        { (openConfirmCambioTransportistaRF) &&
          <ConfirmModal
            title={`Solicitar a RF cambio de agencia en ${selectedPedido.numero_presupuesto}?`}
            description="¿Estás seguro de solicitar el cambio?"
            state={openConfirmCambioTransportistaRF}
            onConfirmAction={handleConfirmCambioTransportistaRF}
            onClose={handleCloseConfirmCambioTransportistaRF}
          />
        }

        { (openLogs) &&
          <InfoModal
            title={"Logs de estado de " + selectedPedido.numero_presupuesto}
            content={
              <LogsTable 
                logs={selectedPedido.logs}
              />
            }
            state={openLogs}
            width={"sm"}
            onClose={handleCloseLogs}
          />
        }

        { (openAssignTransportista) &&
          <InfoModal
            title={"Asignar transportista a " + selectedPedido.numero_presupuesto}
            content={
              <TransportistaSelector 
                onSelectTransportista={handleAssignTransportista}
              />
            }
            state={openAssignTransportista}
            width={"sm"}
            onClose={handleCloseAssignTransportista}
          />
        }

        { (openCancelarPiezas) &&
          <InfoModal
            title={"Cancelar pieza/s del pedido " + selectedPedido.numero_presupuesto}
            content={
              <PiezasSelector
                pedido={selectedPedido} 
                onSubmitCancelarPiezas={handleCancelarPiezas}
              />
            }
            state={openCancelarPiezas}
            width={"sm"}
            onClose={handleCloseCancelarPiezas}
          />
        }
        
        { (openSincronizarUbicacion) &&
          <InfoModal
            title="Sincronizar ubicación de la pieza:"
            content={
              <SincronizarUbicacion
                pedido={selectedPedido} 
                onSubmit={submitSincronizarUbicacion}
                onClose={handleCloseSincronizarUbicacion}
              />
            }
            state={openSincronizarUbicacion}
            width={"sm"}
            onClose={handleCloseSincronizarUbicacion}
          />
        }
      </div>
    </div>
  )
}
