class PedidoStatus {
    constructor(id, name) {
      this.id = id;
      this.name = name;
    }
  }
    
  const Status = {
    PENDIENTE_DE_DESCARGA: new PedidoStatus(1, 'PENDIENTE DE DESCARGA'),
    DESCARGADO: new PedidoStatus(2, 'DESCARGADO'),
    ASIGNADO: new PedidoStatus(3, 'ASIGNADO'),
    COMPLETADO: new PedidoStatus(4, 'COMPLETADO'),
    RECOGIDO: new PedidoStatus(5, 'RECOGIDO'),
    CANCELADO: new PedidoStatus(6, 'CANCELADO'),
    PENDIENTE_DE_CANCELACION: new PedidoStatus(7, 'PENDIENTE DE CANCELACIÓN')
  };
    
    // Utility functions
  const getPedidoStatusById = (id) => Object.values(Status).find(status => status.id === id);
  const getPedidoStatusByName = (name) => Object.values(Status).find(status => status.name === name);
  
  export {
    PedidoStatus,
    Status,
    getPedidoStatusById,
    getPedidoStatusByName
  };