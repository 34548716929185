import { Roles } from "../../../../../../models/roles";

export const SidebarData = [
  {
    title: 'Dashboard',
    path: '/',
    cName: 'nav-text',
    permission: [Roles.ADMIN.id, Roles.USER.id, Roles.MANAGER.id],
  },
  {
    title: 'Dashboard Pantalla',
    path: '/dashboard-pantalla',
    cName: 'nav-text',
    permission: [Roles.ADMIN.id, Roles.USER.id, Roles.MANAGER.id],
  },
  {
    title: 'Etiquetas Sidegal',
    path: '/sidegal',
    cName: 'nav-text',
    permission: [Roles.ADMIN.id, Roles.LABELS.id],
  },
  {
    title: 'Etiquetas Segohon',
    path: '/segohon',
    cName: 'nav-text',
    permission: [Roles.ADMIN.id, Roles.LABELS.id],
  },
  {
    title: 'Usuarios',
    path: '/usuarios',
    cName: 'nav-text',
    permission: [Roles.ADMIN.id],
  },
  {
    title: 'Pedidos pendientes de recoger',
    path: '/recogida',
    cName: 'nav-text',
    permission: [Roles.ADMIN.id, Roles.USER.id],
  },
  {
    title: 'Introducir Vin',
    path: '/vin',
    cName: 'nav-text',
    permission: [Roles.ADMIN.id, Roles.VIN.id],
  },
  {
    title: 'Piezas Sidegal',
    path: '/vin-piezas-sidegal',
    cName: 'nav-text',
    permission: [Roles.ADMIN.id, Roles.VIN.id],
  },
  {
    title: 'Recogida de pedidos',
    path: '/carrier',
    cName: 'nav-text',
    permission: [Roles.ADMIN.id, Roles.USER.id],
  }
];
