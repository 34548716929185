
import { toast } from 'react-toastify';
import { asyncMac, makeAsyncTypes, makeFetchingReducer } from './utils';
import { API_READ_ONLY } from '../views/Main/components/shared/services/read_only';

const initial = {};

export const readOnlyReducer = (state = initial, action) => {
    
    switch(action.type){
        case "read-only/fullfilled": {
            return action.payload;
        }
        default:
            return state
    }
}

const asyncReadOnly = makeAsyncTypes('read-only');

const [setPending, setFullFilled, setError ] = asyncMac(asyncReadOnly);

export const readOnlyFetchingReducer = makeFetchingReducer([
    'read-only/pending', 
    'read-only/fullfilled', 
    'read-only/rejected'
]);

export const fetchReadOnlyData = () => async dispatch => {
    dispatch(setPending());

    try {
        const response = await API_READ_ONLY.getReadOnlyData();
        const data = await response.data;
        dispatch(setFullFilled(data));
        localStorage.setItem('read_only', JSON.stringify(data));
        dispatch({type: 'roles/fullfilled', payload: data.roles});
        dispatch({type: 'plataformas/fullfilled', payload: data.plataformas});
        dispatch({type: 'cdn_services/fullfilled', payload: data.cdn_services});
        dispatch({type: 'hosts/fullfilled', payload: data.hosts});
        dispatch({type: 'estados/fullfilled', payload: data.estados});
        dispatch({type: 'tipo_origenes/fullfilled', payload: data.tipo_origenes});
        dispatch({type: 'actualizaciones/fullfilled', payload: data.actualizaciones});
        dispatch({type: 'users/fullfilled', payload: data.users});
        dispatch({type: 'transportistas/fullfilled', payload: data.transportistas});
        dispatch({type: 'datos_transportistas/fullfilled', payload: data.datosTransportistas});
    } catch (e) {
        toast.error(e.message)
        dispatch(setError(e.message))
    }
} 

export const fetchDatosTransportistas = () => async dispatch => {
    dispatch(setPending());

    try {
        const response = await API_READ_ONLY.getDatosTransportistas();
        const data = await response.data;
        const local = JSON.parse(localStorage.getItem('read_only'));
        localStorage.setItem('read_only', JSON.stringify({...local, datosTransportistas: data.datosTransportistas}));
        dispatch({type: 'datos_transportistas/fullfilled', payload: data.datosTransportistas});
    } catch (e) {
        toast.error(e.message)
        dispatch(setError(e.message))
    }
}

export const readOnlyDataReducer = readOnlyReducer;
export const readOnlyDataStatusReducer = readOnlyFetchingReducer;