import React, { useState } from 'react';
import { toast } from 'react-toastify';
import ScannerForm from '../../shared/components/ScannerForm';
import { API_PEDIDOS } from '../../shared/services/pedidos';
import { getStatusPedido, setErrorMessage } from '../../shared/helpers/functionalities';
import { useDispatch } from 'react-redux';
import { Print } from '@mui/icons-material';
import PiezaPreview from '../../shared/components/PiezaPreview';
import moment from 'moment-timezone';
import { useKeycloak } from '@react-keycloak/web';
import User from '../../../../../models/user'

export default function AsignarPedidos({
    onAssign
}) {
    const { keycloak } = useKeycloak();
    const user = new User(keycloak.token, keycloak.tokenParsed);

    const dispatch = useDispatch();
    const [pieza, setPieza] = useState(null);
    const [pedido, setPedido] = useState(null);

    const handleScanPieza = async(scannedPieza) => {
        dispatch({type: 'loading/set', payload: true});
        setPieza(scannedPieza);

        const response = await API_PEDIDOS.getPedidoByIdRefPieza(scannedPieza)
        .catch(function (error) {
            dispatch({type: 'loading/set', payload: false});
            toast.error(setErrorMessage(error))
        });

        if(response && response.success) {
            setPedido(response.pedido);
            dispatch({type: 'loading/set', payload: false});

            setTimeout(() => {
                handleAssignPedido(response.pedido)
            }, 1200);
        }
    }

    const handleAssignPedido = async(pedido) => {
        const response = await API_PEDIDOS.updatePedido(pedido.id, {
            user_id: user.id,
            estado_id: 3
        });
        
        if(response && response.success) {
            toast.success(response.message);
            setPedido(null);
            setPieza(null);
            onAssign();
        }
    }

    return (
        <div className='w-100'>
            <p className="mb-4">
                Con el lector de código de barras, escanea el pedido. Una vez veas
                reflejado en el campo de texto inferior el número de pedido, haz click
                en siguiente.
            </p>

            <div className="d-flex justify-content-center align-items-center">
                { (!pedido) ?
                    <div className='col-12 col-md-8'>
                        <ScannerForm 
                            type={'pieza'}
                            onScan={handleScanPieza}
                        />
                    </div>
                    :
                    <div className='w-100'>
                        <p>
                            Pieza escaneada: 
                            <span className='fw-bold ms-1'>#{pieza}</span>
                        </p>
                        <div className='w-100 table-responsive'>
                            <table className='table'>
                                <thead>
                                    <tr>
                                        <th>#</th>
                                        <th className='text-center'>fecha</th>
                                        <th className='text-center'>estado</th>
                                        <th>piezas</th>
                                        <th className='text-center'>etiqueta</th>
                                    </tr>
                                </thead>

                                <tbody>
                                    { Object.values(pedido.piezas).map((pieza, i) => {
                                        const presupuesto = i === 0 ? <td rowSpan={Object.values(pedido.piezas).length + 1} width="8%">
                                            <div className='d-flex flex-column align-items-center'>
                                                <small>{pedido.numero_presupuesto}</small>
                                                <small>
                                                    {pedido.plataforma ? pedido.plataforma.nombre : '-'} &nbsp;
                                                </small>
                                                <small>{pedido.codigo_pedido}</small>
                                            </div>
                                        </td> : null
                                        const fecha = i === 0 ?  <td rowSpan={Object.values(pedido.piezas).length + 1} width="12%" className="text-center">
                                            {moment(pedido.fecha).tz('Europe/Madrid').format('DD-MM-YYYY HH:mm:ss')}
                                        </td> : null
                                        const estado = i === 0 ?  <td rowSpan={Object.values(pedido.piezas).length + 1} width="12%" className='text-center text-uppercase'>
                                            <span className='d-flex flex-column'>
                                            <span className={'fw-bold ' + (getStatusPedido(pedido.estado_id))}>{pedido.estado.nombre}</span>
                                            { (pedido.estado_id === 2) &&
                                                <small>{pedido?.responsable?.username}</small>
                                            }
                                            </span>
                                        </td> : null

                                        return (
                                            <tr key={i}>
                                            {presupuesto}
                                            {fecha}
                                            {estado}
                                            <td>
                                                <div className='d-flex align-items-center'>
                                                <div className='flex-grow-1 d-flex flex-column'>
                                                    <span>
                                                    {pieza.nombre}
                                                    </span>
                                                    <span className='fw-bold'>#{pieza.id_referencia_plataforma}</span>
                                                    <span>REF: {pieza.referencia_pieza}</span>
                                                </div>
                                                <div className='d-flex justify-content-end'>
                                                    <span>
                                                        <PiezaPreview
                                                            pieza={pieza}
                                                        />
                                                    </span>
                                                </div>
                                                </div>
                                            </td>
                                            <td className="text-center">
                                                {pieza.etiqueta_envio ? (
                                                <Print style={{ color: 'green' }} />
                                                ) : (
                                                <Print style={{ color: '#bd3030' }} />
                                                )}
                                            </td>
                                        </tr>
                                        )
                                    })}
                                </tbody>
                            </table>
                        </div>
                    </div>
                }
            </div>
        </div>
    );
}
