import {
  AssignmentInd,
  CancelScheduleSend,
  MoreVert,
  Print,
  TaskAlt,
  Restore,
  Preview,
  NotificationAdd,
  PendingActions,
  Edit,
  InfoRounded,
  Upload,
  AssignmentReturn,
  PictureAsPdf,
  Person,
  PersonOff,
  ArrowDownward,
  HistoryToggleOff,
  Cancel,
  Delete,
  PublishedWithChangesOutlined,
  PunchClock,
  UploadFile,
  LocalShipping
} from '@mui/icons-material';
import { IconButton } from '@mui/material';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { alpha, styled } from '@mui/material/styles';
import * as React from 'react';

const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color:
      theme.palette.mode === 'light'
        ? 'rgb(55, 65, 81)'
        : theme.palette.grey[300],
    boxShadow:
      'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
    '& .MuiMenu-list': {
      padding: '4px 0',
    },
    '& .MuiMenuItem-root': {
      '& .MuiSvgIcon-root': {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      '&:active': {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity
        ),
      },
    },
  },
}));

export default function ActionsMenu({ options, onAction, onClose }) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <IconButton
        id="actions-menu-button"
        aria-controls={open ? 'actions-menu-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        variant="contained"
        onClick={handleClick}
      >
        <MoreVert />
      </IconButton>
      <StyledMenu
        id="actions-menu-menu"
        MenuListProps={{
          'aria-labelledby': 'actions-menu-button',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={() => {
          handleClose();
          if (onClose) onClose();
        }}
      >
        {options.map((option, i) => {
          return (
            <MenuItem
              onClick={() => {
                onAction(option.action);
                handleClose();
              }}
              key={'actions-' + i}
              disableRipple
            >
              {
                (option.icon === 'print') ? 
                  <Print />
                : (option.icon === 'shipment_cancel') ? 
                  <CancelScheduleSend />
                : (option.icon === 'preview') ? 
                  <Preview />
                : (option.icon === 'order') ? 
                  <TaskAlt />
                : (option.icon === 'assign') ? 
                  <AssignmentInd />
                : (option.icon === 'desassign') ? 
                  <AssignmentReturn />
                : (option.icon === 'label_request') ? 
                  <NotificationAdd />
                : (option.icon === 'restore') ? 
                  <Restore />
                : (option.icon === 'order_pending') ? 
                  <PendingActions />
                : (option.icon === 'edit') ? 
                  <Edit />
                : (option.icon === 'info') ? 
                  <InfoRounded />
                : (option.icon === 'upload_label') ? 
                  <Upload />
                : (option.icon === 'upload_file') ? 
                  <UploadFile />
                : (option.icon === 'invoice') ? 
                  <PictureAsPdf />
                : (option.icon === 'user') ? 
                  <Person />
                : (option.icon === 'user-slash') ? 
                  <PersonOff />
                : (option.icon === 'down') ? 
                  <ArrowDownward />
                : (option.icon === 'log') ? 
                  <HistoryToggleOff />
                : (option.icon === 'parts_cancel') ? 
                  <Cancel />
                : (option.icon === 'remove') ? 
                  <Delete />
                : (option.icon === 'update') ? 
                  <PublishedWithChangesOutlined />
                : (option.icon === 'shipment_wait') ? 
                  <PunchClock />
                : (option.icon === 'shipment_change') ? 
                  <LocalShipping />
                : null
              }

              { (option.action === 'pedido_descargado') ? 
                <h1 className="fw-bold">
                  {option.label}
                </h1>
                : 
                option.label
              }
            </MenuItem>
          );
        })}
      </StyledMenu>
    </div>
  );
}
