import { combineReducers } from "redux";
import { readOnlyDataReducer, readOnlyDataStatusReducer } from "./redux/readOnly";
import { rolesFullfilledReducer } from "./redux/roles";
import { estadosFullfilledReducer } from "./redux/estados";
import { actualizacionesFullfilledReducer } from "./redux/actualizaciones";
import { plataformasFullfilledReducer } from "./redux/plataformas";
import { hostsFullfilledReducer } from "./redux/hosts";
import { tipoOrigenesFullfilledReducer } from "./redux/tipoOrigen";
import { usersFullfilledReducer } from "./redux/users";
import { loadingReducer } from "./redux/features/loading";
import { reloadReducer } from "./redux/features/reload";
import { searchReducer } from "./redux/features/search";
import { dashboardReducer, dashboardStatusReducer } from "./redux/features/dashboard";
import { cdnServicesFullfilledReducer } from "./redux/cdn_services";
import { recogidaReducer, recogidaStatusReducer } from "./redux/features/recogida";
import { vinReducer, vinStatusReducer } from "./redux/features/vin";
import { transportistasFullfilledReducer } from "./redux/transportistas";
import { datosTransportistasFullfilledReducer } from "./redux/datosTransportistas";
import { sidegalReducer, sidegalStatusReducer } from "./redux/features/piezasSidegal";
import { vinPiezasSidegalReducer, vinPiezasSidegalStatusReducer } from './redux/features/vinPiezasSidegal';
import { dashboardPantallaReducer } from './redux/features/dashboardPantalla';
import { segohonReducer, segohonStatusReducer } from "./redux/features/piezasSegohon";

const reducer = combineReducers({
    // UTILITIES
    search: searchReducer,
    // READ ONLY DATA
    read_only: combineReducers({
        entities: readOnlyDataReducer,
        status: readOnlyDataStatusReducer,
    }),
    dashboard: combineReducers({
        entity: dashboardReducer,
        status: dashboardStatusReducer
    }),
    dashboard_pantalla: dashboardPantallaReducer,
    recogidas: combineReducers({
        entity: recogidaReducer,
        status: recogidaStatusReducer
    }),
    vin: combineReducers({
        entity: vinReducer,
        status: vinStatusReducer
    }),
    vin_piezas_sidegal: combineReducers({
        entity: vinPiezasSidegalReducer,
        status: vinPiezasSidegalStatusReducer
    }),
    piezas_sidegal: combineReducers({
        entity: sidegalReducer,
        status: sidegalStatusReducer
    }),
    piezas_segohon: combineReducers({
        entity: segohonReducer,
        status: segohonStatusReducer
    }),
    //-------------------------------
    actualizaciones: actualizacionesFullfilledReducer,
    cdn_services: cdnServicesFullfilledReducer,
    estados: estadosFullfilledReducer,
    hosts: hostsFullfilledReducer,
    plataformas: plataformasFullfilledReducer,
    roles: rolesFullfilledReducer,
    tipo_origenes: tipoOrigenesFullfilledReducer,
    users: usersFullfilledReducer,
    transportistas: transportistasFullfilledReducer,
    datos_transportistas: datosTransportistasFullfilledReducer,
    //-------------------------------
    // CURRENT DATA
    loading: loadingReducer,
    reload: reloadReducer,
})

const rootReducer = reducer;

export default rootReducer;