/* eslint-disable jsx-a11y/alt-text */
import { PictureAsPdf, Print } from '@mui/icons-material';
import { Checkbox } from '@mui/material';
import Pagination from '@mui/material/Pagination';
import { React, useEffect } from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment-timezone';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBook, faExclamationTriangle, faQrcode, faTape } from '@fortawesome/free-solid-svg-icons';
import { Es, Fr, Us } from 'react-flags-select';
import { getActions } from '../actions';
import ActionsMenu from '../../../shared/components/ActionsMenu';
import { getStatusPedido } from '../../../shared/helpers/functionalities';
import PiezaPreview from '../../../shared/components/PiezaPreview';

export default function Table({ 
  id,
  page,
  filterStatus,
  filterValues,
  pedidos,
  selectedPedidos,
  isAdmin,
  pageCount,
  massEditMode,
  selectAllChecked,
  onPageChange,
  onSelectPedido,
  onSelectAllPedidos,
  onAction,
  onOpenModalPieza,
  onOpenSelectAlbaranLanguage
}) {
  const dashboard = useSelector(state => {
    return state.dashboard.entity
  });

  useEffect(() => {
    
  }, [pedidos, filterStatus, filterValues]);

  return (
    <div className="w-100 d-flex flex-column align-items-end content-table presupuestos">
        <div className="w-100 table-responsive">
            <table className="table">
                <thead>
                    <tr>
                        <th className="text-center">
                            {massEditMode && (
                                <div className='d-flex align-items-center'>
                                <Checkbox
                                    color='success'
                                    checked={selectAllChecked}
                                    onChange={onSelectAllPedidos}
                                    className='p-0'
                                />
                                    <b>Todos</b>
                                </div>
                            )}
                        </th>
                        <th></th>
                        <th>#</th>
                        <th className='text-center'>fecha</th>
                        <th className='text-center'>estado</th>
                        <th className='text-center'>ubicación</th>
                        <th>pieza</th>
                        <th className='text-center'>etiqueta</th>
                        <th></th>
                    </tr>
                </thead>

                {pedidos?.length === 0 ? (
                    <tbody>
                        <tr>
                            <td colSpan={9} className="text-center">
                                No hay pedidos pendientes
                            </td>
                        </tr>
                    </tbody>
                ) : (
                    pedidos?.map((pedido, index) => {
                        const piezasValues = Object.values(pedido.piezas)
                        const piezasRows = piezasValues.filter(p => id === 'historico' ? true : !p.cancelada).map((pieza, i) => {
                        const massive = i === 0 ?
                        <td rowSpan={piezasValues.length + 1} width={'5%'} className="text-center">
                            {massEditMode ? (
                            <Checkbox
                                color='success'
                                checked={selectedPedidos.find(p => p.origin_id === pedido.origin_id)}
                                onChange={() => onSelectPedido(pedido)}
                            />
                            ) : ''
                            }
                        </td>
                        : null;
                        const icons = i === 0 ?
                        <td rowSpan={piezasValues.length + 1} width={'5%'} className=" text-center">
                            <div className='d-flex flex-column'>
                            {
                                pedido?.plataforma?.id === 20 && 
                                <span className='me-2 fs-5 text' title='Cinta BParts'>
                                <FontAwesomeIcon 
                                    icon={faTape} 
                                    size="1x" 
                                    color={
                                    pedido.cintaType === 'servicebox' ? '#1A2F52' 
                                    : pedido.cintaType === 'bparts' ? '#359AD2'
                                    : "#215732"
                                    } />
                                </span>
                            }
                            { pedido.flyer ?
                                <span className='me-2 fs-5 text' title='Flyer'>
                                <FontAwesomeIcon icon={faBook} size="1x" color="#215732" />
                                </span>
                                : null
                            }

                            { pedido.qr ?
                                <span className='me-2 d-flex flex-column align-items-center fs-5 text mt-1' title='QR Piezas'>
                                <FontAwesomeIcon icon={faQrcode} size="1x" className='mb-1' color="#215732" />
                                { (pedido.lang === 'ES') ?
                                    <Es />
                                    : (pedido.lang === 'FR') ? 
                                    <Fr />
                                    :
                                    <Us />
                                }
                                </span>
                                : null
                            }
                            </div>
                        </td>
                        : null;
                        const presupuesto = i === 0 ? <td rowSpan={piezasValues.length + 1} width="8%">
                        <div className="d-flex flex-column">
                            <span>
                            {pedido.numero_presupuesto}
                            </span>

                            <span className='fw-bold text-uppercase text-info'>{pedido?.plataforma ? pedido.plataforma.nombre : ''}</span>
                        </div>
                        

                        </td> : null
                        const fecha = i === 0 ?  <td rowSpan={piezasValues.length + 1} width="12%" className="text-center">
                        {moment(pedido.fecha).tz('Europe/Madrid').format('DD-MM-YYYY HH:mm:ss')}
                        </td> : null
                        const estado = i === 0 ?  <td rowSpan={piezasValues.length + 1} width="12%" className='text-center text-uppercase'>
                        <span className='d-flex flex-column'>
                            <span className={'fw-bold ' + (getStatusPedido(pedido.estado_id))}>{pedido.estado.nombre}</span>
                            { (pedido.estado_id === 3) &&
                            <small>{pedido?.responsable?.username}</small>
                            }
                        </span>
                        </td> : null
                        const acciones = i === 0 ?  <td rowSpan={piezasValues.length + 1} className="text-center">
                        { (getActions(pedido, id, isAdmin)?.length > 0) ?
                            <ActionsMenu
                            options={getActions(pedido, id, isAdmin)}
                            onAction={(action) => onAction(action, pedido)}
                            />
                            : null
                        }
                        
                        </td> : null
                            
                        return (
                            <tr key={i}>
                                {massive}
                                {icons}
                                {presupuesto}
                                {fecha}
                                {estado}
                                <td className='text-center'>
                                <span className='fw-bold'>{pieza.ubicacion}</span>
                                </td>
                                <td>
                                <div className='w-100 d-flex align-items-center'>
                                    <div className='flex-grow-1 d-flex flex-column'>
                                    {pieza.cancelada ? <small className='fw-bold text-danger'>CANCELADA</small> : ''}
                                    <span
                                        onClick={() => onOpenModalPieza(pieza)}
                                        style={{
                                        cursor: 'pointer',
                                        color: '#215731',
                                        }}
                                    >
                                        { (dashboard.logs_seinto?.find(ls => ls.pieza_id === pieza.id)) ?
                                            <span className='me-2' title={"No se ha podido actualizar el estado"}>
                                            <FontAwesomeIcon icon={faExclamationTriangle} size="1x" color="#dc3545" />
                                            </span>
                                            : null
                                        }
                                        {pieza.nombre}
                                    </span>
                                    <span className='fw-bold'>#{pieza.id_referencia_plataforma}</span>
                                    <span>REF: {pieza.referencia_pieza}</span>
                                    { (id === 'historico' && pedido.estado_id === 5) ?
                                        <span className='mt-1' style={{cursor: 'pointer'}} onClick={() => onOpenSelectAlbaranLanguage(pedido, pieza)}>
                                        <PictureAsPdf />
                                        </span>
                                        : ''
                                    }
                                    <div className='d-flex flex-column'>
                                        <span>
                                        <span className='fw-bold text-info me-1'>{pieza?.transportista?.nombre}</span>
                                        <span className='fw-bold'>{pieza?.numero_seguimiento}</span>
                                        </span>
                                        
                                        <span>
                                        Hora de entrega:
                                        <span className='fw-bold ms-1'>{pieza.fecha_recogida ? moment(pieza.fecha_recogida).format('DD-MM-YYYY HH:mm:ss') : '-'}</span>
                                        </span>
                                    </div>
                                    
                                    </div>
                                    <div className='d-flex justify-content-end'>
                                    <span
                                        onClick={() => onOpenModalPieza(pieza)}
                                        style={{
                                        cursor: 'pointer',
                                        color: '#215731',
                                        }}
                                    >
                                        <PiezaPreview
                                            pieza={pieza}
                                            onOpenModal={onOpenModalPieza}
                                        />
                                    </span>
                                    </div>
                                </div>
                                </td>
                                <td className="text-center">
                                {pieza.etiqueta_envio ? (
                                    <Print style={{ color: 'green' }} />
                                ) : (
                                    <Print style={{ color: '#ffc107' }} />
                                )}
                                </td>
                                {acciones}
                            </tr>
                        )
                    })

                        return (
                            <tbody key={index}>
                                {piezasRows}
                            </tbody>
                        )
                    })
                )}
            </table>
        </div>

        <Pagination 
            className="mt-3" 
            count={pageCount} 
            page={page} 
            onChange={onPageChange} 
        />
    </div>
  )
}
