class User {
    constructor(token, parsedToken) {
        this.id = parsedToken.sub
        this.name = parsedToken.name
        this.locale = parsedToken.locale
        this.username = parsedToken.preferred_username
        this.email = parsedToken.email
        this.token = token
        this.company = parsedToken.company
        this.roles = parsedToken['resource_access'].almacen.roles
        this.createdAbsences = []
    }

    toJson() {
        return {
            id: this.id,
            name: this.name,
            locale: this.locale,
            username: this.username,
            email: this.email,
            token: this.token// Serialize the token using the Token class's toJSON method
        };
    }

    static fromJSON(json) {
        
        const obj = JSON.parse(json);
        const token = JSON.stringify(obj.token);  // Deserialize the token object
        return new User(token, obj);  // Return the User instance with the token and parsed data
    }
}

module.exports = User;