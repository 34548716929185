import API from '../../../../../services/api';

export const API_PANTALLA = {
    getAllInfo: async () => {
        return await API.get('/dashboard-pantalla/dashboard-info')
        .then(res => res.data);
    },
    getRefreshInfo: async () => {
        return await API.get('/dashboard-pantalla/refresh')
        .then(res => res.data);
    },
};
