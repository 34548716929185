import React from 'react';
import { Line } from 'react-chartjs-2';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';

// Registrar componentes de Chart.js
ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
);

const DashboardCharts = ({ data }) => {
    // Extraer datos del array
    const labels = data.map((item) => item.semana);
    const cantidades = data.map((item) => item.cantidad);
    const facturaciones = data.map((item) => item.facturacion);

    const cantidadData = {
        labels,
        datasets: [
            {
                data: cantidades,
                borderColor: 'rgb(43, 117, 66)',
                backgroundColor: 'rgba(33, 87, 50, 0.2)',
                borderWidth: 2,
                pointRadius: 3,
                pointBackgroundColor: 'rgba(33, 87, 50, 1)',
                tension: 0.3,
            },
        ],
    };

    const optionsCantidad = {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
            legend: { display: false },
            tooltip: {
                mode: 'index',
                intersect: false,
            },
        },
        scales: {
            x: {
                ticks: { display: false },
                grid: { drawTicks: false },
            },
            y: {
                beginAtZero: true,
                ticks: {
                    callback: (value) => `${value} pzs.`,
                },
            },
        },
    };

    return (
        <div className="dashboard-charts">
            <div className="chart-container">
                <h3>PIEZAS VENDIDAS ÚLTIMAS 20 SEMANAS</h3>
                <div className="chart-container__chart">
                    <Line data={cantidadData} options={optionsCantidad} />
                </div>
            </div>
        </div>
    );
};

export default DashboardCharts;
