import { toast } from 'react-toastify';
import { API_PANTALLA } from '../../views/Main/components/shared/services/dashboard_pantalla';

const initialDashboardPantalla = {
    current: null,
    records: null,
    parts: null,
    ordersByStatus: null,
};

export const dashboardPantallaReducer = (
    state = initialDashboardPantalla,
    action
) => {
    switch (action.type) {
        case 'dashboard-pantalla/fullfilled': {
            return action.payload;
        }
        case 'dashboard-pantalla/updated': {
            return {
                ...state,
                current: action.payload.current,
                parts: action.payload.parts
                    ? [...state.parts.slice(0, -1), action.payload.parts]
                    : state.parts,
            };
        }
        case 'dashboard-pantalla/new-records': {
            return {
                ...state,
                records: action.payload,
            };
        }
        default:
            return state;
    }
};

const setLoading = (dispatch, isLoading) =>
    dispatch({ type: 'loading/set', payload: isLoading });

export const fetchDashboardPantalla = () => async (dispatch) => {
    setLoading(dispatch, true);

    try {
        const allInfo = await API_PANTALLA.getAllInfo();

        dispatch({
            type: 'dashboard-pantalla/fullfilled',
            payload: {
                current: allInfo.data.current,
                records: allInfo.data.records,
                parts: allInfo.data.partsData,
                ordersByStatus: allInfo.data.ordersByStatus
            },
        });
    } catch (e) {
        toast.error(e.message);
    } finally {
        setLoading(dispatch, false);
    }
};

export const fetchRefreshPantalla =
    (loading = false) =>
    async (dispatch) => {
        if (loading) {
            setLoading(dispatch, true);
        }
        try {
            const currentInfo = await API_PANTALLA.getRefreshInfo();
            dispatch({
                type: 'dashboard-pantalla/updated',
                payload: {
                    current: currentInfo.data.current,
                    parts: currentInfo.data.lastPartsData[0],
                    ordersByStatus: currentInfo.data.ordersByStatus,
                },
            });
        } catch (e) {
            toast.error('No se ha podido actualizar la info del dashboard');
        } finally {
            if (loading) {
                setLoading(dispatch, false);
            }
        }
    };

export const fetchNewRecord = (current, records) => async (dispatch) => {
    let newRecords = structuredClone(records);

    if (current.pedidosHoy > records.recordDia.total_pedidos) {
        newRecords.recordDia = {
            dia: new Date().toLocaleDateString('es-ES', {
                day: '2-digit',
                month: '2-digit',
                year: '2-digit',
            }),
            total_pedidos: current.pedidosHoy,
        };
    }

    if (current.pedidosSemana > records.recordSemana.total_pedidos) {
        const today = new Date();
        const dayOfWeek = today.getDay();

        const diffToMonday = dayOfWeek === 0 ? -6 : 1 - dayOfWeek;
        const monday = new Date(today);
        monday.setDate(today.getDate() + diffToMonday);

        const sunday = new Date(monday);
        sunday.setDate(monday.getDate() + 6);

        newRecords.recordSemana = {
            semana: `${formatDate(monday)}-${formatDate(sunday)}`,
            total_pedidos: current.pedidosSemana,
        };
    }

    if (current.pedidosMes > records.recordMes.total_pedidos) {
        const today = new Date();
        newRecords.recordMes = {
            mes: `${String(today.getMonth() + 1).padStart(
                2,
                '0'
            )}/${today.getFullYear()}`,
            total_pedidos: current.pedidosMes,
        };
    }

    return dispatch({
        type: 'dashboard-pantalla/new-records',
        payload: newRecords,
    });
};

const formatDate = (date) => {
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = String(date.getFullYear()).slice(-2);
    return `${day}/${month}/${year}`;
};
