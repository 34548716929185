import { useState } from 'react';
import './sincronizarUbicacion.scss';
import { API_PIEZAS } from '../../../shared/services/piezas';
import { toast } from 'react-toastify';
import { CircularProgress } from '@mui/material';

const SincronizarUbicacion = ({ pedido, onSubmit, onClose }) => {
    const { piezas } = pedido;
    const [part, setPart] = useState(piezas[0]);
    const [isLoading, setIsLoading] = useState(false);

    const handleChangePart = (id) => {
        const selectedPart = piezas.find((pieza) => pieza.id === id);
        setPart(selectedPart);
    };

    const submitSincronizar = async () => {
        if (isLoading) return;
        setIsLoading(true);
        const res = await API_PIEZAS.sincronizarUbicacion(part.id);
        if (res?.success) {
            onSubmit(pedido.id, part.id, res.ubicacion);
            toast.success('Ubicación sincronizada correctamente');
            return onClose();
        }
        setIsLoading(false);
        toast.error('No se ha podido sincronizar la ubicación');
    };

    return (
        <div className="sincronizar-ubicacion">
            <div className="sincronizar-ubicacion__container-parts">
                {piezas.map((p) => (
                    <label
                        key={p.id}
                        className={p.id === part.id ? 'selected' : ''}
                    >
                        <input
                            type="radio"
                            name="pieza"
                            value={p.id}
                            checked={part?.id === p.id}
                            onChange={() => handleChangePart(p.id)}
                        />
                        <p className="sincronizar-ubicacion__part-info">
                            <span className="part-id">
                                #{p.id_referencia_plataforma}
                            </span>
                            <span className="part-name">{p.nombre}</span>
                        </p>
                    </label>
                ))}
            </div>

            <div className="d-flex flex-row align-items-end justify-content-end gap-2 w-100 mt-4">
                <button className="btn btn-danger" onClick={onClose}>
                    Cancelar
                </button>
                <button className="btn btn-primary" onClick={submitSincronizar}>
                    {isLoading ? <CircularProgress size="23px" color="inherit" /> : "Sincronizar"}
                </button>
            </div>
        </div>
    );
};

export default SincronizarUbicacion;
