// src/services/dashboardService.js

import { toast } from 'react-toastify';
import { sendNotifcationOficina } from '../api/index';
import { getEtiqueta, printMergedPDF } from '../pdf';
import config from '../../config';
import { API_PIEZAS } from '../../views/Main/components/shared/services/piezas';
import { setErrorMessage } from '../../views/Main/components/shared/helpers/functionalities';

const host_crm = config.recomotor.crmApiUrl;
const token_external = config.recomotor.externalToken;

const handleEtiquetaOficina = async (pieza, body) => {
  const name_pieza = pieza.nombre;
  try {
    const resultado_notificacion = await sendNotifcationOficina(
      host_crm,
      token_external,
      body
    );
    if (resultado_notificacion.success) {
      toast.success(
        `Se ha notificado a la oficina la etiqueta de ${name_pieza}`
      );
      const res = await API_PIEZAS.notificado(pieza.id);
      if (!res.success) {
        throw Error('Failed to update the piece status');
      }
    } else {
      toast.warn(`Error al notificar la etiqueta de ${name_pieza}`);
    }
  } catch (error) {
    console.error(error);
    toast.warn(`Error al notificar la etiqueta de ${name_pieza}`);
  }
};

const handleImprimirEtiquetaMassiva = async (pedidos) => {
  let allEtiquetas = [];
  for (const pedido of pedidos) {
    let etiquetas = [];
    const {labelParts, isMultipleIds} = handleGetLabelParts(pedido, pedido.plataforma.nombre);

    for (let pieza of labelParts) {
      const { id, origin_id } = pieza;
      const idsToPrint = handleGetIdsToPrint(
        pieza, 
        pedido, 
        isMultipleIds);
      const pdf = await handleGetEtiqueta(pedido, pieza, idsToPrint)
      .catch(function (error) {
        toast.error(setErrorMessage(error))
        throw error;
      });
  
      if(pdf) {
        etiquetas.push({
          pieza_id: id,
          pieza_origin_id: origin_id,
          pdf: pdf,
        });
      }
    }

    allEtiquetas = allEtiquetas.concat(etiquetas);
  }

  if (allEtiquetas.length > 0) {
    const pdfBase64s = allEtiquetas.map((tiquet) => tiquet.pdf);
    printMergedPDF(pdfBase64s);
  }
};

const handleImprimirEtiqueta = async (pedido) => {
  let etiquetas = [];
  const {labelParts, isMultipleIds} = handleGetLabelParts(pedido, pedido?.plataforma?.nombre);
  
  for (let pieza of labelParts) {
    const { id, origin_id } = pieza;
    const idsToPrint = handleGetIdsToPrint(
      pieza, 
      pedido, 
      isMultipleIds);
    const pdf = await handleGetEtiqueta(pedido, pieza, idsToPrint)
    .catch(function (error) {
      toast.error(setErrorMessage(error))
      throw error;
    });

    if(pdf) {
      etiquetas.push({
        pieza_id: id,
        pieza_origin_id: origin_id,
        pdf: pdf,
      });
    }
  }
  
  if (etiquetas.length > 0) {
    const pdfBase64s = etiquetas.map((tiquet) => tiquet.pdf);
    printMergedPDF(pdfBase64s);
  }
};

export const handleGetLabelParts = (pedido, plataforma) => {
  let labelParts = pedido.piezas;
  let noLabelParts = [];

  if(labelParts.length > 1) {
    // one label with all ids
    if(plataforma === 'RecambioFacil' || plataforma === 'Ovoko') {
      noLabelParts = labelParts.slice(1);
      labelParts = [labelParts[0]];
    } else {
      noLabelParts = pedido?.piezas.filter(p => !p.etiqueta_envio);
      if(noLabelParts.length > 0) {
        labelParts = pedido?.piezas.filter(p => p.etiqueta_envio)
      } else {
        // check if there are parts in the same shipping box (more than one part in order and at least one is carrier id 1)
        noLabelParts = labelParts.filter(p => p.transportista.id === 1);
        if (noLabelParts.length > 0) {
          if(labelParts.length !== noLabelParts.length) {
            labelParts = labelParts.filter(p => p.transportista.id !== 1);
          } else {
            // if all parts are carrier id 1 (recoge cliente) only print one label
            labelParts = [labelParts[0]];
          }
        }
      }
    }
  }

  return {
    labelParts, 
    isMultipleIds: noLabelParts.length > 0
  }
}

export const handleGetIdsToPrint = (part, order, isMultipleIds) => {
  if(isMultipleIds) {
    return order.piezas.map((pieza) => '#' + pieza.id_referencia_plataforma).join(', ')
  }
  return '#' + part.id_referencia_plataforma
}

const handleGetEtiqueta = async(pedido, pieza, idsToPrint) => {
  const { envio_id, notificado, transportista } = pieza;
  if (envio_id !== null) {
    try {
      const pdf = await getEtiqueta(envio_id, pedido, idsToPrint, transportista?.nombre);
      if (pdf !== null) {
       
        if (!notificado) {
          const res = await API_PIEZAS.notificado(pieza.id);
          if (!res.success) {
            throw Error('Failed to update the piece status');
          }
        }

        return pdf;
      } else {
        if (pedido.notificado) {
          const body = {
            id_envio: pieza.envio_id,
            num_presupuesto: pedido.numero_presupuesto,
            created_by: pedido.created_by,
          };
          await handleEtiquetaOficina(pieza, body);
        }
        toast.warn(
          `No se ha podido obtener la etiqueta, ya ha sido notificado a la oficina.`
        );

        return null
      }
    } catch (error) {
      console.error('Error fetching the label:', error);
    }
  }
}

const handleSolicitarEtiqueta = async (pedido) => {
  const num_presupuesto = pedido?.presupuesto?.numero;
  const created_by = pedido?.presupuesto?.created_by;
  const piezasParaProcesar = pedido.piezas.filter(
    (pieza) => !pieza.notificado && pieza.envio_id !== null
  );

  if (piezasParaProcesar.length === 0) {
    toast.info('No hay piezas que notificar o ya han sido notificadas.');
    return [];
  }

  // Si hay piezas para procesar, procede con las solicitudes.
  const requests = piezasParaProcesar.map(async (pieza) => {
    try {
      const etiqueta = await getEtiqueta(pieza?.envio_id, pedido);
      if (etiqueta === null) {
        const body = {
          id_envio: pieza?.envio_id,
          num_presupuesto,
          created_by,
        };
        await handleEtiquetaOficina(pieza, body);
      } else {
        await API_PIEZAS.notificado(pieza.id);
      }
    } catch (error) {
      console.error(`Error al solicitar la etiqueta: ${pieza?.envio_id}`, error);
    }
  });

  await Promise.all(requests);

  if (piezasParaProcesar.length > 0) {
    toast.success(
      'Las piezas sin etiqueta han sido notificadas correctamente.'
    );
  }

  return piezasParaProcesar;
};

const getReferenciasPiezas = (piezas) => {
  const ids_plataforma = piezas.map((pieza) => pieza.id_referencia_plataforma);
  return ids_plataforma.length > 1
    ? ids_plataforma.join(', ')
    : ids_plataforma[0];
};



const DashboardService = {
  handleEtiquetaOficina,
  handleImprimirEtiqueta,
  handleSolicitarEtiqueta,
  getReferenciasPiezas,
  handleImprimirEtiquetaMassiva
};

export default DashboardService;
