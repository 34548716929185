module.exports = {
  app: {
    version: `${process.env.REACT_APP_VERSION || 'dev'}`,
    originUrl: `${process.env.REACT_APP_ORIGIN}`
  },
  recomotor: {
    baseUrl: `${process.env.REACT_APP_API_BASE}`,
    crmApiUrl: `${process.env.REACT_APP_HOST_CRM}`,
    voApiUrl: `${process.env.REACT_APP_HOST_VENTA_ONLINE}`,
    externalToken: `${process.env.REACT_APP_API_TOKEN_EXTERNAL}`,
  },
  keycloak: {
      url: process.env.REACT_APP_KEYCLOAK_URL,
      realm: process.env.REACT_APP_KEYCLOAK_REALM,
      clientId: process.env.REACT_APP_KEYCLOAK_CLIENT_ID
  }
};
