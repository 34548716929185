const EachSquareResults = ({title, results, extraInfo, className = ''}) => {
  return (
    <div className={`each-square-results ${className}`}>
      <h3 className='each-square-results__title'>{title}</h3>
      {extraInfo && <p className='each-square-results__extra'>{extraInfo}</p>}
      <p className='each-square-results__results'>{results}</p>
    </div>
  )
}

export default EachSquareResults;
