import API from '../../../../../services/api';

export const API_PIEZAS = {
  notificado: async (id) => {
    return await API.put(`/pieza/${id}/notifica`).then((res) => res.data);
  },
  sincronizarUbicacion: async (partId) => {
    return await API.post(`/pieza/sincronizar_ubicacion`, {partId})
      .then((res) => res.data).catch(error => error.response);
  }
};
