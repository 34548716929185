class Role {
    constructor(id, name) {
      this.id = id;
      this.name = name;
    }
  }
  
  const Roles = {
    ACCESS: new Role(0, 'access'),
    ADMIN: new Role(1, 'admin'),
    USER: new Role(2, 'user'),
    VIN: new Role(3, 'vin'),
    MANAGER: new Role(4, 'manager'),
    CARRIER: new Role(5, 'carrier'),
    LABELS: new Role(6, 'labels')
  };
  
  // Utility functions
  const getRoleById = (id) => Object.values(Roles).find(role => role.id === id);
  const getRoleByName = (name) => Object.values(Roles).find(role => role.name === name);
  
  export {
    Role,
    Roles,
    getRoleById,
    getRoleByName,
  };
