import React, { useEffect } from "react";
import { useForm } from "react-hook-form";

export default function SegohonForm({
    pieza,
    onSubmitInfo
}) {
    const { register, formState: { errors }, handleSubmit } = useForm({
        mode: "onChange",
        defaultValues: {
            id_referencia_plataforma: pieza.id_referencia_plataforma,
            ubicacion: '',
        }
    });

    const handleSubmitInfo = async(data, e) => {
        e.preventDefault();
        onSubmitInfo(data);
    }
    
    return (
        <div className="w-100 d-flex flex-column">
            <form className="form mb-4" onSubmit={handleSubmit(handleSubmitInfo)}>
                <div className="w-100 d-flex flex-column">
                    <input 
                        type="hidden" 
                        {...register("id_referencia_plataforma")} 
                        defaultValue={pieza.id_referencia_plataforma} />

                    <div className="form-group me-3">
                        <label htmlFor="nombre">Ubicación</label>
                        <input 
                            type="text" 
                            className="form-control" 
                            {...register("ubicacion", {required: true})} 
                            defaultValue={''} />

                        <div className="form-invalid">
                            {(errors.ubicacion) && errors.ubicacion?.message}
                        </div>
                    </div>
                </div>

                <div className="d-flex align-items-center align-self-end">
                    <button type="submit" className="btn btn-primary align-self-end">Actualizar</button>
                </div>
            </form>
        </div>
    )
}